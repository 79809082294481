import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar, Freeze } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { SelectConfigData } from '../../Services/ControlPanelAPI'
import { GetCurrentDate } from '../../Services/Common'
import {SelectcustomersList  } from "../../Services/CustomersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse,SelectBatchDetails, SelectItemsForInv} from '../../Services/StockTransferAPI'
import { GetMaxSaleInvoiceNo,SelectSaleInvoiceMaster,SelectSaleInvoiceDetail,SaveSaleInvoice } from '../../Services/SaleInvoiceAPI'
import { Selectbank } from '../../Services/bankAPI'
import SaleOrderModal from './../SaleOrder/SaleOrderModal'
import Loader from '../Loader'
export class SaleInvoiceEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        SaleInvoiceNo: '',
          CustomerList: [],
          SelectedCustomer: null,
          BankList:[],
          SelectedBank:null,
          Batch_List:[],
        Selected_Batch:null,
          SelectedPaymentType:null,
          InvoiceDate: GetCurrentDate(),
          CreatedDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Discription: '',
          SI_Detail_Data: [],
          Supplier_Err: '',
          Bank_Err:'',
        UOMID:0,
        Bank_Disable_Prop:false,
        Total_Disable_Prop:false,
        Balance_Disable_Prop:false,
          TotalQuantity:0,
            TotalDiscount:0,
            TotalTaxAmt:0,
            InvoiceAmount:0,
            GrandTotal:0,
            ServiceCharges: 0,
            Balance:0,
            TotalPaid:0,
            Discription:'',
            Cash:0,
            ChangeAmt:0,
            SelectedBatchData:null,
        // Modal 
        Stock_Modal : false,
        btn_edit : true,
        IsCancel: false,
        btn_Disabled:false,
        IsLoader:false,

        // Hide or Show Columns
        DisAmt_Visible: true,
        DisPer_Visible: true,
        SaleTaxAmt_Visible:true,
        SaleTaxPer_Visible:true,
        StockQt:0,
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
    //   this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
      this._Options= [
          {value:'Cash Sale',title:'Cash Sale'},
          {value:'Credit Sale',title:'Credit Sale'},
          {value:'Account Sale',title:'Account Sale'},
          {value:'Cash Credit Sale',title:'Cash Credit Sale'},
          {value:'Account Credit Sale',title:'Account Credit Sale'}
        ]
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }

  componentDidMount(){

    document.title = " Pharmacy / Sale Invoice"
    this.GetMax_SaleInvoiceNo()
    Selectbank(this.Comp_Id,this.ProjectID,(mBank)=>{
        this.setState({BankList:mBank})
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse, SelectedWareHouse: mWareHouse[0]}, () =>{
            this.Select_Item_Details(mWareHouse[0].WareHouseID)
        })
        SelectcustomersList(this.Comp_Id,this.ProjectID,(mCustomer)=>{
        this.setState({CustomerList:mCustomer,SelectedCustomer:mCustomer[0]})

        if(this.props.isAddNew) // save
        {
       
            // this.GetMax_SaleInvoiceNo() // new reciver NO genrate
           
            //  this.setState({SelectedPaymentType: {value:'Cash Sale',title:'Cash Sale'},Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
            SelectConfigData(this.Comp_Id, (mData)=>{
                mWareHouse.map(val => {
                    if(val.WareHouseID == mData[0].DefaultWareHouseID)
                    {
                        
                        this.setState({SelectedWareHouse:val})
                        this.Select_Item_Details(val.WareHouseID)
                    }
                })
                this._Options.map(val =>{
                
                    if(val.value == mData[0].PaymentTypeForINV)
                    {
    
                        this.setState({SelectedPaymentType:val},()=>{
                            if(val.value === 'Account Sale'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, 
                                    Balance_Disable_Prop:true})
                            }
                            else if (val.value === 'Account Credit Sale'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false,
                                     Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Credit Sale'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, 
                                    Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Sale'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, 
                                    Balance_Disable_Prop:true})
                            }
                        })
                    }
                })
              
            })
        }
        else // update
        {
            this.showLoader()
            SelectSaleInvoiceMaster(this.Comp_Id,this.ProjectID,this.props.SaleInvoiceID,(mData)=>{
               
                this.setState({
                    SaleInvoiceNo:mData[0].SaleInvoiceNo,
                    InvoiceDate:mData[0].InvoiceDate,
                    Discription:mData[0].Discription,
                    TotalDiscount:mData[0].TotalDiscount,
                    TotalQuantity:mData[0].TotalQuantity,
                    InvoiceAmount:mData[0].InvoiceAmount,
                    GrandTotal:mData[0].GrandTotal,
                    Cash:mData[0].Cash,
                    ChangeAmt:mData[0].ChangeAmt,
                    TotalPaid:mData[0].TotalPaid,
                    Balance:mData[0].Balance,
                    ServiceCharges: mData[0].ServiceCharges,
                    SelectedPaymentType: this._Options.find(x => x.value === mData[0].PaymentType),
                    btn_edit: this.props.show_screen.edit
                })

                if(mData[0].PaymentType === "Account Credit Sale" ||mData[0].PaymentType === "Account Sale")
                {
                    this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                }
                else
                {
                    this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                }
               
                this.Select_Item_Details(mData[0].WareHouseID)
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                mBank.map(val=>{
                    if(val.BankID === mData[0].BankID)
                    {
                        this.setState({SelectedBank:val})
                    }
                })
                mCustomer.map(val=>{
                    if(val.CustomerID === mData[0].CustomerID)
                    {
                        this.setState({SelectedCustomer:val})
                    }
                })
              
               
                SelectSaleInvoiceDetail(this.Comp_Id,this.ProjectID,this.props.SaleInvoiceID,async(mDetailData)=>{
                    // mDetailData.map(async(value,index)=>{
                    //     value.SrNo = index + 1 
                    //     value.StockQty+=value.Qty
                    //     value.Qty = value.Qty
                    //     await new Promise(resolveForBatchQTy=>{
                    //         SelectBatchDetails(this.Comp_Id,this.ProjectID,value.ItemID,mWareHouse[0].WareHouseID,(mItemsBatchQty)=>{ // Api
                    //             for(let i =0; i< mItemsBatchQty.length; i++){
                    //                    if((value.BatchNo).trim() === (mItemsBatchQty[i].BatchNo).trim()){
                    //                         value.BQty = mItemsBatchQty[i].BQty
                    //                    }
                    //             }
                    //             resolveForBatchQTy()
                    //         })
                    //     })
                    //    value.BQty += value.Qty
                    // })
                    for (let index = 0; index < mDetailData.length; index++) {
                        let value = mDetailData[index];
                        
                        value.SrNo = index + 1;
                        value.StockQty += value.Qty;
                        value.Qty = value.Qty;
                        
                        await new Promise(resolveForBatchQTy => {
                            SelectBatchDetails(this.Comp_Id, this.ProjectID, value.ItemID, mWareHouse[0].WareHouseID, (mItemsBatchQty) => {
                                for (let i = 0; i < mItemsBatchQty.length; i++) {
                                    if ((value.BatchNo).trim() === (mItemsBatchQty[i].BatchNo).trim()) {
                                        value.BQty = mItemsBatchQty[i].BQty;
                                    }
                                }
                                resolveForBatchQTy();  // Resolve after batch quantity processing
                            });
                        });
                        
                        value.BQty += value.Qty;
                    }
                    
                    this.setState({SI_Detail_Data:mDetailData})
                })
            })
            this.hideLoader()
        }
    
       })
    })
})

// Sale Tax Column hide
SelectConfigData(this.Comp_Id,(mConfigData)=>{
    if (mConfigData[0].ApplyTax_on_SaleInvoice === 1)
    {
        this.setState({
         DisAmt_Visible: mConfigData[0].IsDisAmt === 1 ? true : false ,
         DisPer_Visible:mConfigData[0].IsDisPer === 1 ? true : false ,
         SaleTaxAmt_Visible:mConfigData[0].IsSaleTaxAmt === 1 ? true : false ,
         SaleTaxPer_Visible:mConfigData[0].IsSaleTaxPer === 1 ? true : false ,
        },()=>{
            this.grid.refresh()
        })
    }
    
})
}
showLoader = () => {
   
    this.setState({IsLoader:true})
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }
  validate = ()=>{
    var bank_err =''
    if (this.state.SelectedPaymentType) {
        if (this.state.SelectedPaymentType.value === 'Account Sale' || this.state.SelectedPaymentType.value === 'Account Credit Sale') {
            if (!this.state.SelectedBank)
                bank_err = 'required'
        }
    }
  
    
    if (bank_err){
        this.setState({Bank_Err:bank_err})
        return false
    }
   
    return true
}
    Select_Item_Details = (WareHouseID) =>{
        SelectItemsForInv(this.Comp_Id,this.ProjectID,WareHouseID,(mItems)=>{ // Api
            if(this.Comp_Id === 6 && this.ProjectID === 6){
                mItems.map(val=>{
                    val.ItemDiscription = val.ItemCode + ' ~ '+  val.ItemDiscription
                })
            }
            
            this.setState({Item_List:mItems})
        })
    }
    Select_BatchDetails = (ItemID) =>{
        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
            
            this.setState({Batch_List:mItems})
        })
    }
    Show_Min_Stock_Modal = ()=>{
        this.setState({Stock_Modal:true})
    }
    Hide_Min_Stock_Modal = ()=>{
        this.setState({Stock_Modal:false, isCancel: true})
    }

    IsCancel = () =>{
        this.setState({isCancel: false})
    }
    GetMax_SaleInvoiceNo = () => {
        this.showLoader()  
        GetMaxSaleInvoiceNo(this.Comp_Id,this.ProjectID,'INV', (mSaleInvoiceNo) => { // API Function
            this.hideLoader() 
                this.setState({ SaleInvoiceNo: mSaleInvoiceNo })  // set state here 
            })
    }
     useStyles = makeStyles({
        option: {
          "&:hover": {
            backgroundColor: "#8C99E0 !important",
            color:"#fff"
          },
          '&.MuiAutocomplete-option[data-focus="true"]':{  //arrow above or below key
            backgroundColor: "#8C99E0 !important",
            color:"#fff"
          }
        }
      });
    BatchNO_Auto = null
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                classes={{
                    option: this.useStyles().option,
                  }}
                defaultValue={this.state.Item_List.find(v => v.ItemID === rowValue.ItemID)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        this.setState({ Selected_item: value },()=>{
                            this.Select_Batch_Detail(value.ItemID,this.state.SelectedWareHouse.WareHouseID)
                        })
                    }
                    else {
                        this.setState({ Selected_item: null,Batch_List:[],Selected_Batch:null })
                        // document.getElementById('UOM').value = ''
                       
                        var BatchNo_select = document.getElementById('BatchNo')
                        var length = BatchNo_select.options.length;
                        for (let i = length - 1; i >= 0; i--) {
                            BatchNo_select.options[i] = null;
                        }
                    }
                }}
                // renderOption={(props, option) => {
                //     return (
                //       <span {...props} style={{ backgroundColor: 'red' }}>
                //         {props.ItemDiscription}
                //       </span>
                //     );
                //   }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Select_Batch_Detail = (ItemID, WareHouseID) =>{
        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,WareHouseID,(mItems)=>{ // Api
            var BatchNo_select = document.getElementById('BatchNo')
       
            var length = BatchNo_select.options.length;
            for (let i = length - 1; i >= 0; i--) {
                BatchNo_select.options[i] = null;
            }
            for (let i = 0; i < mItems.length; i++) {
                BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].BatchNo , mItems[i].BatchNo)
            }
            BatchNo_select.selectedIndex = 0
        //     this.BatchNO_Auto.props.options.splice(0, this.BatchNO_Auto.props.options.length)
        //     for (var i = 0; i < mItems.length; i++) {
        //         this.BatchNO_Auto.props.options.push(mItems[i])
               
        //     }
        //    var mmm = document.getElementById('BatchNo')
           if(mItems.length > 0){
            // mmm.value=  mItems[0].batchno 
            // document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
            document.getElementById('BQty').value = mItems[0].BQty
            document.getElementById('SaleRate').value = mItems[0].SaleRate
            document.getElementById('value').value = mItems[0].SaleRate
           }
            this.setState({Batch_List:mItems,SelectedBatchData:mItems[0]})
        })
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
                
                this.Calculate_Total()
               
            }}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    BatchNo = (rowValue) => {
        return (<select 
            id="BatchNo" name="BatchNo" 
            style={{ fontSize: 17, textAlign: "right", width: "100%" }}
            onChange={e => {
                var Qty = 0;
                this.state.Batch_List.find(value => {
                    if (e.target.value == value.batchno) {
                        document.getElementById('BQty').value =value.BQty
                        document.getElementById('SaleRate').value =value.SaleRate
                        document.getElementById('value').value =value.SaleRate
                       
                        this.setState({ SelectedBatchData: value })
                    }
                })
            }}
            className="textbox" ></select>
        )

    }
    // BatchNO_Auto = null
    // BatchNo = (rowValue) => {
       
    //     this.BatchNO_Auto = <Autocomplete
    //     name="BatchNo"
    //     id="BatchNo"
    //     defaultValue={rowValue.BatchNo ? { batchno: rowValue.BatchNo} : ''}
    //     options={this.state.Batch_List}
    //     getOptionLabel={(option) => option.batchno}
    //     getOptionSelected={(option, value) => {
    //         if (value === "") {
    //           return true;
    //         }
    //         else if (value.BatchNo === option.batchno) {
    //           return true;
    //         }
    //       }}
    //     onChange={(e, value) => {
    //         if (value) {
    //             this.setState({ Selected_Batch: value })
    //             // document.getElementById('ExpairyDate').value = value.ExpairyDate
    //             document.getElementById('BQty').value = value.BQty
    //             document.getElementById('SaleRate').value = value.SaleRate
    //             document.getElementById('value').value = value.SaleRate
    //         }
    //         else {
    //             this.setState({ Selected_Batch: null })
    //             // document.getElementById('ExpairyDate').value = ''
    //             document.getElementById('BQty').value = ''
    //             document.getElementById('SaleRate').value =  ''
    //             document.getElementById('value').value = ''
    //         }
    //     }}
    //     renderInput={(params) =>
    //         <TextField  {...params} name="BatchNo" id='BatchNo' />}
    // />
    //     return (
    //         this.BatchNO_Auto
    //     )
    // }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            readOnly={true}

        />)
    }
    BQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BQty"
            defaultValue={rowValue.BQty}
            className="textbox"
            name="BQty"
            readOnly={true}
            
        />)
    }
    SaleRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="SaleRate"
            defaultValue={rowValue.SaleRate}
            className="textbox"
            name="SaleRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
  
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }

    SaveHandler = (isFromPrint) => {
       if(this.validate()){
        let _PurchaseValue = 0;
                if(this.state.SelectedCustomer)
        {
            this.setState({Supplier_Err:''})
            if(this.state.SI_Detail_Data.length > 0)
            {
                
              

                for(let i = 0; i< this.state.SI_Detail_Data.length; i++)
                {
                    if( typeof this.state.SI_Detail_Data[i].BatchNo === undefined || this.state.SI_Detail_Data[i].BatchNo === 'undefined' || this.state.SI_Detail_Data[i].BatchNo == '' || this.state.SI_Detail_Data[i].BatchNo == null )
                    {
                        Swal.fire({
                            icon: 'info',
                            text: `Batch No at Sr No ${this.state.SI_Detail_Data[i].SrNo} Must be Entered...`,
                        })
                        return
                    }

                    if(typeof this.state.SI_Detail_Data[i].Qty === 'undefined' || this.state.SI_Detail_Data[i].Qty == '' || this.state.SI_Detail_Data[i].Qty == null )
                    this.state.SI_Detail_Data[i].Qty = 0
                    else if (typeof this.state.SI_Detail_Data[i].Qty === 'string')
                    this.state.SI_Detail_Data[i].Qty =  parseFloat(this.state.SI_Detail_Data[i].Qty)

                    if(typeof this.state.SI_Detail_Data[i].TaxPer === 'undefined' || this.state.SI_Detail_Data[i].TaxPer == '' || this.state.SI_Detail_Data[i].TaxPer == null )
                    this.state.SI_Detail_Data[i].TaxPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].TaxPer === 'string')
                    this.state.SI_Detail_Data[i].TaxPer =  parseFloat(this.state.SI_Detail_Data[i].TaxPer)

                    if(typeof this.state.SI_Detail_Data[i].DisPer === 'undefined' || this.state.SI_Detail_Data[i].DisPer == '' || this.state.SI_Detail_Data[i].DisPer == null )
                    this.state.SI_Detail_Data[i].DisPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisPer === 'string')
                    this.state.SI_Detail_Data[i].DisPer =  parseFloat(this.state.SI_Detail_Data[i].DisPer)

                    if(typeof this.state.SI_Detail_Data[i].TaxAmt === 'undefined' || this.state.SI_Detail_Data[i].TaxAmt == '' || this.state.SI_Detail_Data[i].TaxAmt == null )
                    this.state.SI_Detail_Data[i].TaxAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].TaxAmt === 'string')
                    this.state.SI_Detail_Data[i].TaxAmt =  parseFloat(this.state.SI_Detail_Data[i].TaxAmt)

                    if(typeof this.state.SI_Detail_Data[i].DisAmt === 'undefined' || this.state.SI_Detail_Data[i].DisAmt == '' || this.state.SI_Detail_Data[i].DisAmt == null )
                    this.state.SI_Detail_Data[i].DisAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisAmt === 'string')
                    this.state.SI_Detail_Data[i].DisAmt =  parseFloat(this.state.SI_Detail_Data[i].DisAmt)

                    if(typeof this.state.SI_Detail_Data[i].SaleRate === 'undefined' || this.state.SI_Detail_Data[i].SaleRate == '' || this.state.SI_Detail_Data[i].SaleRate == null )
                    this.state.SI_Detail_Data[i].SaleRate = 0
                    else if (typeof this.state.SI_Detail_Data[i].SaleRate === 'string')
                    this.state.SI_Detail_Data[i].SaleRate =  parseFloat(this.state.SI_Detail_Data[i].SaleRate)
                    
                    if(typeof this.state.SI_Detail_Data[i].Value === 'undefined' || this.state.SI_Detail_Data[i].Value == '' || this.state.SI_Detail_Data[i].Value == null )
                    this.state.SI_Detail_Data[i].Value = 0
                    else if (typeof this.state.SI_Detail_Data[i].Value === 'string')
                    this.state.SI_Detail_Data[i].Value =  parseFloat(this.state.SI_Detail_Data[i].Value)
                    this.state.SI_Detail_Data[i].ExpairyDate = GetCurrentDate();
                    
                    this.state.SI_Detail_Data[i].PurchaseValue=parseFloat((this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].PurchaseRate).toFixed(2)) 
                    _PurchaseValue += this.state.SI_Detail_Data[i].PurchaseValue
                }
                var SaleData = {
                    SaleInvoiceID:this.props.SaleInvoiceID,
                    SaleInvoiceNo:this.state.SaleInvoiceNo,
                    WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                    CustomerID:this.state.SelectedCustomer.CustomerID,
                    UserID:this.UserID,
                    CreatedDate:this.state.CreatedDate,
                    InvoiceDate:this.state.InvoiceDate,
                    Discription:this.state.Discription,
                    TotalDiscount:this.state.TotalDiscount,
                    TotalQuantity:this.state.TotalQuantity,
                    InvoiceAmount:this.state.InvoiceAmount,
                    GrandTotal:this.state.GrandTotal,
                    TransactionType:'INV',
                    BankID:this.state.SelectedBank?this.state.SelectedBank.BankID:0,
                    PaymentType:this.state.SelectedPaymentType.value,
                    TotalPaid: this.state.SelectedPaymentType.value === 'Cash Credit Sale' || 
                    this.state.SelectedPaymentType.value === 'Account Credit Sale' 
                    ? this.state.TotalPaid : this.state.SelectedPaymentType.value === 'Cash Sale' || this.state.SelectedPaymentType.value === 'Account Sale' ? this.state.GrandTotal :  this.state.TotalPaid,
                    Balance:this.state.Balance,
                    Comp_Id:this.Comp_Id,
                    ProjectID:this.ProjectID,
                    Cash: this.state.Cash,
                    ChangeAmt: this.state.ChangeAmt,
                    PurchaseAmount: _PurchaseValue,
                    ServiceCharges: this.state.ServiceCharges
                }
                this.setState({btn_Disabled:true})
            
                SaveSaleInvoice(SaleData,this.state.SI_Detail_Data,this.props.isAddNew,(SaleInvoiceID, status)=>{
                    
                    this.setState({btn_Disabled:false})
                    
                    if(!isFromPrint && !status){
                        document.getElementById('btnCancel').click()
                    }else if(isFromPrint && !status){
                         var obj = {
                                    InvoiceNo: this.state.SaleInvoiceNo,
                                    InvoiceDate: this.state.InvoiceDate,
                                    Discription: this.state.Discription,
                                    Supplier: this.state.SelectedCustomer ,
                                    Warehouse: this.state.SelectedWareHouse,
                                    TotalDiscount: this.state.TotalDiscount,
                                    TotalQuantity: this.state.TotalQuantity,
                                    InvoiceAmount: this.state.InvoiceAmount,
                                    GrandTotal: this.state.GrandTotal,
                                    Cash: this.state.Cash,
                                    ChangeAmt: this.state.ChangeAmt,
                                    TotalPaid: this.state.TotalPaid,
                                    Balance: this.state.Balance,
                                    PaymentType: this.state.SelectedPaymentType,
                                    Detail: this.state.SI_Detail_Data,
                                    ServiceCharges: this.state.ServiceCharges
                                }
                                
                        localStorage.setItem("SaleInvoice", JSON.stringify(obj))
                         window.open(`/SaleInvoiceReport/${SaleInvoiceID}`,"_blank")
                        document.getElementById('btnCancel').click()
                    }
                   
                })
            }
            else
            { 
                Swal.fire({
                    icon: 'info',
                    text: `Sale Details Must be Entered...`,
                })
                return
            }
        }
        else
        {
            this.setState({Supplier_Err:'Customer is required'})
        }
    
       }
        
       
    }
    beforeRowInsert = (rowValue) => {
        if(rowValue.requestType === 'save')
        {
           
            rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
            rowValue.data.ItemID = this.state.Selected_item.ItemID
            rowValue.data.PurchaseRate = this.state.Selected_item.PurchaseRate
            rowValue.data.UOMID = this.state.UOMID
            // if(rowValue.data.action === 'add'){
               
            // }else if(rowValue.data.action === 'edit'){
            //     if(this.state.Selected_Batch){
            //         rowValue.data.BatchNo = this.state.Selected_Batch.BatchNo
            //     }
            // }
            if(this.state.SelectedBatchData){
                rowValue.data.BatchNo = this.state.SelectedBatchData.BatchNo
            }
           
          else{
            rowValue.data.BatchNo = ''
          }
            if(parseFloat(rowValue.data.Qty) > parseFloat(rowValue.data.BQty))
            {
                Swal.fire({
                    icon: 'info',
                    text: `Qty must be less than the Batch Qty : ${rowValue.data.BQty} Given...`,
                })
                this.grid.closeEdit()

            }
            if(parseFloat(rowValue.data.Qty) > parseFloat(rowValue.data.StockQty))
            {
                Swal.fire({
                    icon: 'info',
                    text: `Qty must be less than or equal to your current stock`,
                })
                this.grid.closeEdit()

            }
            if (rowValue.data.SaleInvoiceDetailID === undefined)
                rowValue.data.action = 'add'
            else
                rowValue.data.action = rowValue.action
        }
        else if (rowValue.requestType === 'add')
        {
            rowValue.data.SrNo = this.state.SI_Detail_Data.length + 1
            rowValue.data.Qty = 1
            rowValue.data.Value = 0.00
            rowValue.data.SaleRate = 0.00
            rowValue.data.DisPer = 0.00
            rowValue.data.DisAmt = 0.00
            rowValue.data.TaxPer = 0.00
            rowValue.data.TaxAmt = 0.00

            this.setState({ Selected_item: null,UOMID:0,SelectedBatchData:null })
        }





    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID,
                "PurchaseRate":rowValue.rowData.PurchaseRate },UOMID: rowValue.rowData.UOMID },()=>{
                // this.Select_Batch_Detail(rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID)
                // SelectBatchDetails(this.Comp_Id,this.ProjectID,rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
                //     var BatchNo_select = document.getElementById('BatchNo')

                //     var length = BatchNo_select.options.length;
                //     for (let i = length - 1; i >= 0; i--) {
                //         BatchNo_select.options[i] = null;
                //     }

                //     for (let i = 0; i < mItems.length; i++) {
                //         if(mItems[i].BatchNo === rowValue.rowData.BatchNo){
                //             BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].BatchNo, mItems[i].BatchNo)
                //             this.setState({SelectedBatchData:mItems[i]})
                //         }
                //     }
                //     BatchNo_select.selectedIndex = 0
                //     this.setState({Batch_List:mItems})
                // })
                SelectBatchDetails(this.Comp_Id,this.ProjectID,rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
          
                    var BatchNo_select = document.getElementById('BatchNo')

                    var length = BatchNo_select.options.length;
                    for (let i = length - 1; i >= 0; i--) {
                        BatchNo_select.options[i] = null;
                    }

                    for (let i = 0; i < mItems.length; i++) {
                        if((mItems[i].BatchNo).trim() == (rowValue.rowData.BatchNo).trim()){
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].BatchNo, mItems[i].BatchNo)
                            BatchNo_select.selectedIndex = i
                            this.setState({SelectedBatchData:mItems[i]})
                        }
                        else{
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].BatchNo, mItems[i].BatchNo)
                        }
                    }
                    
                    this.setState({Batch_List:mItems})
                })
            })
            
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values();
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Values();
        }
    }
   
    Calculate_Total = () => {
        var Qty = 0, Rate = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

        Rate = document.getElementById('SaleRate').value == "" ? 0 : parseFloat(document.getElementById('SaleRate').value)
        if(this.state.DisPer_Visible){
            DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)

        }
        if(this.state.SaleTaxPer_Visible){
            TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)

        }

        DisAmt = parseFloat(((DisPer / 100) * (Rate * Qty)).toFixed(2))
        TaxAmt = parseFloat(((TaxPer / 100) * (Rate * Qty)).toFixed(2))
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        if(this.state.DisAmt_Visible){
            document.getElementById('DisAmt').value = DisAmt;

        }
        if(this.state.SaleTaxAmt_Visible){
            document.getElementById('TaxAmt').value = TaxAmt;

        }
        document.getElementById('value').value = Total_Value;

    }
    Calculate_Values = () =>{
        var _TotalQuantity = 0, _TotalDis = 0, _TotalTax = 0, _TotalPur = 0, _GrandTotal = 0, Rate=0, Qty=0
        for(let i = 0; i< this.state.SI_Detail_Data.length; i++)
        {
           if( typeof this.state.SI_Detail_Data[i].Qty === 'string' && typeof this.state.SI_Detail_Data[i].DisAmt === 'string' && typeof this.state.SI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.SI_Detail_Data[i].SaleRate === 'string' && typeof this.state.SI_Detail_Data[i].value === 'string'  )
           {
            this.state.SI_Detail_Data[i].Qty = parseFloat((this.state.SI_Detail_Data[i].Qty).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].DisAmt = parseFloat((this.state.SI_Detail_Data[i].DisAmt).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].TaxAmt = parseFloat((this.state.SI_Detail_Data[i].TaxAmt).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].SaleRate = parseFloat((this.state.SI_Detail_Data[i].SaleRate).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].Value = parseFloat((this.state.SI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (this.state.SI_Detail_Data[i].Qty == '' && typeof this.state.SI_Detail_Data[i].DisAmt === '' && typeof this.state.SI_Detail_Data[i].TaxAmt === '' && typeof this.state.SI_Detail_Data[i].SaleRate === '' && typeof this.state.SI_Detail_Data[i].value === '' )
           {
            this.state.SI_Detail_Data[i].Qty = 0 
            this.state.SI_Detail_Data[i].DisAmt = 0
            this.state.SI_Detail_Data[i].TaxAmt = 0
            this.state.SI_Detail_Data[i].SaleRate = 0
           this.state.SI_Detail_Data[i].Value = 0
           }
           _TotalQuantity += parseFloat(this.state.SI_Detail_Data[i].Qty)
           _TotalDis += parseFloat(this.state.SI_Detail_Data[i].DisAmt)
           _TotalTax += parseFloat(this.state.SI_Detail_Data[i].TaxAmt)
           _TotalPur += parseFloat(this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].SaleRate)
            _GrandTotal += parseFloat(this.state.SI_Detail_Data[i].Value)
        }

        this.setState({TotalQuantity:_TotalQuantity,TotalDiscount:_TotalDis,TotalTaxAmt:_TotalTax,InvoiceAmount:_TotalPur.toFixed(2),GrandTotal:_GrandTotal.toFixed(2)})
       
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    GrandAmount_Calculation = (TotalDiscount, TotalTaxAmount, InvoiceAmount, ServiceCharges) =>{
        var _total = 0.00;

        _total = (parseFloat(TotalTaxAmount) + parseFloat(InvoiceAmount) + parseFloat(ServiceCharges)) - parseFloat(TotalDiscount)

        this.setState({GrandTotal : _total})
    }
    LoadStockToGrid = mData =>{
        this.setState({ SI_Detail_Data: this.state.SI_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.SI_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray})
        })
        this.Hide_Min_Stock_Modal()
    }
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
           <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Sale Invoice</h4>
            </Col>
          </Row>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="SaleInvoiceNo"
                            label="Sale Invoice No" 
                            value={this.state.SaleInvoiceNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="CustomerName"
                            id="CustomerName"
                            options={this.state.CustomerList}
                            getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                            value={this.state.SelectedCustomer}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedCustomer:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedCustomer:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="CustomerName" label="Customer"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                                    this.Select_Item_Details(value.WareHouseID)
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.InvoiceDate}
                            name="InvoiceDate"
                            label="InvoiceDate" 
                            type= 'date'
                            onChange= {e => this.setState({InvoiceDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PaymentType"
                            id="PaymentType"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedPaymentType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedPaymentType:value},()=>{
                                        if(value.value === 'Account Sale'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                        else if (value.value === 'Account Credit Sale'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Credit Sale'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Sale'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                    })
                              
                                }
                                else
                                {
                                    this.setState({SelectedPaymentType:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PaymentType" label="Payment Type"
                                />}
                        />
                        {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                
                    
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="BankName"
                            id="BankName"
                            options={this.state.BankList}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            value={this.state.SelectedBank}
                            disabled={this.state.Bank_Disable_Prop}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedBank:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedBank:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="BankName" label="BankName"
                                />}
                        />
                        <span className="text-danger">{this.state.Bank_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalPaid"
                            label="TotalPaid" 
                            value={this.state.TotalPaid}
                            disabled={this.state.Total_Disable_Prop}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalPaid:e.target.value},()=>{
                                let bal =    this.state.GrandTotal -  this.state.TotalPaid
                                  this.setState({Balance:bal})
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Balance"
                            label="Balance" 
                            value={this.state.Balance}
                            disabled={this.state.Balance_Disable_Prop}
                            // onInput={this.toInputUppercase}
                            inputProps={
                                {readOnly: true}
                            }
                            onChange={e=>this.setState({Balance:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                </Row>
             
                    
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent  gridLines='Both' enableStickyHeader={true} frozenColumns={4} dataSource={this.state.SI_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition:'Top'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                <ColumnDirective width='100' field='SrNo' headerText='Sr#' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'dropdownedit'  editTemplate={this.ItemDiscription}   edit={this.cbParams}  validationRules={this.NAmeValidation} textAlign='Left'/> 
                                    <ColumnDirective width='130' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='center'/>
                                    <ColumnDirective width='150' field='SaleRate' headerText='SaleRate'   editTemplate={this.SaleRate} textAlign='center'></ColumnDirective>
                                    {/* <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} /> */}
                                    <ColumnDirective width='150' field='BatchNo' headerText='Batch#' editType= 'dropdownedit'  editTemplate={this.BatchNo}   edit={this.cbParams}   textAlign='Left'/> 
                                   {/*  <ColumnDirective width='170' field='ExpairyDate' headerText='Expiry Date'  editTemplate={this.ExpairyDate} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='140' field='BQty' headerText='BatchBal.'   editTemplate={this.BQty} textAlign='right'></ColumnDirective>
                                   
                                 
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis%' visible={this.state.DisPer_Visible}  editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt' visible={this.state.DisAmt_Visible}  editTemplate={this.DisAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText='Tax%' visible={this.state.SaleTaxPer_Visible}  editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' headerText='TaxAmt' visible={this.state.SaleTaxAmt_Visible}  editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='center' isFrozen='true'></ColumnDirective>

                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='SaleInvoiceDetailID' headerText='SaleInvoiceDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='SaleInvoiceID' headerText='SaleInvoiceID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective width='140' field='StockQty' headerText='StockQty'  visible={false}  textAlign='right'></ColumnDirective>
                                    
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar, Freeze]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalQuantity"
                            label="TotalQuantity" 
                            value={this.state.TotalQuantity}
                            inputProps={
                                {readOnly: true}
                            }
                            onChange={e=>this.setState({TotalQuantity:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDiscount"
                            label="TotalDiscount" 
                            type='number'
                            value={this.state.TotalDiscount}
                            inputProps={
                                {readOnly: this.state.DisAmt_Visible}
                            }
                            onChange={e=>{
                                this.setState({TotalDiscount:e.target.value})
                                this.GrandAmount_Calculation(e.target.value, this.state.TotalTaxAmt, this.state.InvoiceAmount, this.state.ServiceCharges)

                            
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt"
                            label="TotalTaxAmt" 
                            type='number'
                            value={this.state.TotalTaxAmt}
                            inputProps={
                                {readOnly: this.state.DisAmt_Visible}
                            }
                            onChange={e=>{
                                this.setState({TotalTaxAmt:e.target.value})
                                this.GrandAmount_Calculation(this.state.TotalDiscount,e.target.value, this.state.InvoiceAmount, this.state.ServiceCharges)
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="InvoiceAmount"
                            label="InvoiceAmount" 
                            value={this.state.InvoiceAmount}
                            inputProps={
                                {readOnly: true}
                            }
                            onChange={e=>this.setState({InvoiceAmount:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField
                        name="ServiceCharges"
                        label="ServiceCharges" 
                        value={this.state.ServiceCharges}
                        type='number'
                        // onInput={this.toInputUppercase}
                        onChange={e=>{
                            
                            this.setState({ServiceCharges:e.target.value})
                            if(e.target.value == "" || e.target.value < 0){
                                e.target.value = 0
                            }
                            this.GrandAmount_Calculation(this.state.TotalDiscount,this.state.TotalTaxAmt, this.state.InvoiceAmount, e.target.value)
                        }}
                        fullWidth
                    />
                </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            value={this.state.GrandTotal}
                            inputProps={
                                {readOnly: true}
                            }
                            // onInput={this.toInputUppercase}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    </Row>
                  
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Cash"
                            label="Cash" 
                            value={this.state.Cash}
                            // onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Cash:e.target.value},()=>{
                                let balc =    this.state.GrandTotal -  this.state.Cash
                                  this.setState({ChangeAmt:balc})
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="ChangeAmt"
                            label="ChangeAmt" 
                            value={this.state.ChangeAmt}
                            inputProps={
                                {readOnly: true}
                            }
                            onChange={e=>this.setState({ChangeAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                             <Link id="btnGetItems" className="btn btn-primary" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Items from Sale Order</u></Link>
                             {this.state.btn_edit &&(
                                <button type="button"
                                 className="btn btn-primary"
                                   style={{border: "none"}}
                                    onClick={e=>this.SaveHandler(false)}
                                    disabled={this.state.btn_Disabled}
                                    > 
                                    {this.state.btn_Disabled?(
                                        <><Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Saving... </> ):<><Save /> <u>Save</u></>}
                                    </button>)}
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'/SaleInvoiceList'} > <Cancel /> <u>Close</u></Link>
                            <button
                             className="btn btn-primary" 
                             style={{border: "none"}} 
                             disabled={this.state.btn_Disabled}
                             onClick={e =>{
                                this.SaveHandler(true)
                             }}
                             > {this.state.btn_Disabled?(
                                <><Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> Saving... </> ):<><Print /> <u>{"Save & Print"}</u></>} </button>
                    </Col>
                    </Row>
                    <SaleOrderModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        CustomerID={this.state.SelectedCustomer && this.state.SelectedCustomer.CustomerID}
                        projectID={ this.ProjectID }
                        setIsCancel = {this.IsCancel}
                        isCancel={ this.state.IsCancel }
                    />
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                  />
                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.SI && state.SI.isAddNew,
    SaleInvoiceID:state.SI && state.SI.SaleInvoiceID,
    show_screen: state.SCREEN,
    Project_ID: state.SI && state.SI.Project_ID
})
export default connect(mapStateToProps)(SaleInvoiceEntry)