import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Loader from '../Loader'
import { SelectConfigData } from '../../Services/ControlPanelAPI'
import { GetCurrentDate } from '../../Services/Common'
import {SelectcustomersList  } from "../../Services/CustomersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse,SelectBatchDetails, SelectBatchDetailsForSR,SelectItemsForInv} from '../../Services/StockTransferAPI'
import { GetMaxSaleInvoiceNo,SelectSaleInvoiceMaster,SelectSaleInvoiceDetail,SaveSaleInvoice,SelectSaleInvoiceMasterWRTCode,SelectSaleInvoiceDetailWRTCode } from '../../Services/SaleInvoiceAPI'
import { Selectbank } from '../../Services/bankAPI'
import MinStockModal from './SaleReturnModal'
export class SaleInvoiceEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        SaleInvoiceNo: '',
          CustomerList: [],
          SelectedCustomer: null,
          BankList:[],
          SelectedBank:null,
          Batch_List:[],
          Selected_Batch:null,
          SelectedPaymentType:null,
          InvoiceDate: GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Discription: '',
          SI_Detail_Data: [],
          Supplier_Err: '',
        UOMID:0,
        Bank_Disable_Prop:false,
        Total_Disable_Prop:false,
        Balance_Disable_Prop:false,
          TotalQuantity:0,
            TotalDiscount:0,
            TotalTaxAmt:0,
            InvoiceAmount:0,
            GrandTotal:0,
            Balance:0,
            TotalPaid:0,
            Discription:'',
            RefInvoiceNo: '',
        // Modal 
        Stock_Modal : false,
        btn_edit : true,
        btn_Disabled:false,
        IsLoader:false,
        // MBatchNo : ''
      
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
    //   this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id
      this.UserID = this.props.p_userid
      this.ProjectID = this.props.ProjectID
      this._Options= [
          {value:'Cash Sale',title:'Cash Sale'},
          {value:'Credit Sale',title:'Credit Sale'},
          {value:'Account Sale',title:'Account Sale'},
          {value:'Cash Credit Sale',title:'Cash Credit Sale'},
          {value:'Account Credi tSale',title:'Account Credit Sale'}
        ]
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }

  componentDidMount(){

    document.title = " Pharmacy / Sale Invoice"
   
    Selectbank(this.Comp_Id,this.ProjectID,(mBank)=>{
        this.setState({BankList:mBank})
   
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse,SelectedWareHouse: mWareHouse[0]}, () =>{
            this.Select_Item_Details(mWareHouse[0].WareHouseID)
           
        })
    
    SelectcustomersList(this.Comp_Id,this.ProjectID,(mCustomer)=>{
       
        this.setState({CustomerList:mCustomer,SelectedCustomer:mCustomer[0]})
        if(this.props.isAddNew) // save
        {
       
            this.GetMax_SaleInvoiceNo() // new reciver NO genrate
            // this.setState({SelectedPaymentType: {value:'Cash Sale',title:'Cash Sale'},Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
            SelectConfigData(this.Comp_Id, (mData)=>{
                mWareHouse.map(val => {
                    if(val.WareHouseID == mData[0].DefaultWareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                this._Options.map(val =>{
                
                    if(val.value == mData[0].PaymentTypeForINV)
                    {
    
                        this.setState({SelectedPaymentType:val},()=>{
                            if(val.value === 'Account Sale'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, 
                                    Balance_Disable_Prop:true})
                            }
                            else if (val.value === 'Account Credit Sale'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false,
                                     Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Credit Sale'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, 
                                    Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Sale'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, 
                                    Balance_Disable_Prop:true})
                            }
                        })
                    }
                })
              
            })
        }

        else // update
        {
            this.showLoader()
            SelectSaleInvoiceMaster(this.Comp_Id,this.ProjectID,this.props.SaleInvoiceID,(mData)=>{
                this.setState({
                    SaleInvoiceNo:mData[0].SaleInvoiceNo,
                    InvoiceDate:mData[0].InvoiceDate,
                    Discription:mData[0].Discription,
                    TotalDiscount:mData[0].TotalDiscount,
                    TotalQuantity:mData[0].TotalQuantity,
                    InvoiceAmount:mData[0].InvoiceAmount,
                    GrandTotal:mData[0].GrandTotal,
                    Cash:mData[0].Cash,
                    ChangeAmt:mData[0].ChangeAmt,
                    TotalPaid:-(mData[0].TotalPaid),
                    Balance:mData[0].Balance,
                    SelectedPaymentType: this._Options.find(x => x.value === mData[0].PaymentType),
                    btn_edit: this.props.show_screen.edit
                })

                this.Select_Item_Details(mData[0].WareHouseID)
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                mBank.map(val=>{
                    if(val.BankID === mData[0].BankID)
                    {
                        this.setState({SelectedBank:val})
                    }
                })
                mCustomer.map(val=>{
                    if(val.CustomerID === mData[0].CustomerID)
                    {
                        this.setState({SelectedCustomer:val})
                    }
                })
                // mWareHouse.map(val=>{
                //     if(val.WareHouseID === mData[0].WareHouseID)
                //     {
                //         this.setState({SelectedWareHouse:val})
                //     }
                // })
                // mSupplier.map(val=>{
                //     if(val.SupplierID === mData[0].SupplierID)
                //     {
                //         this.setState({SelectedCustomer:val})
                //     }
                // })
               
                SelectSaleInvoiceDetail(this.Comp_Id,this.ProjectID,this.props.SaleInvoiceID,(mDetailData)=>{
                    
                    mDetailData.map((value,index)=>{
                        value.SrNo = index + 1 
                        value.Qty = -(value.Qty)
                    })
                    this.setState({SI_Detail_Data:mDetailData})
                })
            })
            this.hideLoader()
        }
    
       })
    })
})
  }
  
  showLoader = () => {
   
    this.setState({IsLoader:true})
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }
  Select_Item_Details = (WareHouseID) =>{
    SelectItemsForInv(this.Comp_Id,this.ProjectID,WareHouseID,(mItems)=>{ // Api
        this.setState({Item_List:mItems})
    })
  }
  Select_BatchDetails = (ItemID) =>{
    SelectBatchDetailsForSR(this.Comp_Id,this.ProjectID,ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
       
        this.setState({Batch_List:mItems})
    })
}
  Show_Min_Stock_Modal = ()=>{
      this.setState({Stock_Modal:true})
  }
  Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
}
GetMax_SaleInvoiceNo = () => {
    this.showLoader()  
    GetMaxSaleInvoiceNo(this.Comp_Id,this.ProjectID,'SR', (mSaleInvoiceNo) => { 
        this.hideLoader()    
            this.setState({ SaleInvoiceNo: mSaleInvoiceNo })  
        })
}
useStyles = makeStyles({
    option: {
      "&:hover": {
        backgroundColor: "#8C99E0 !important",
        color:"#fff"
      },
      '&.MuiAutocomplete-option[data-focus="true"]':{  //arrow above or below key
        backgroundColor: "#8C99E0 !important",
        color:"#fff"
      }
    }
  });
    BatchNO_Auto = null
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                classes={{
                    option: this.useStyles().option,
                  }}
                defaultValue={this.state.Item_List.find(v => v.ItemID === rowValue.ItemID)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        
                        // var test = document.getElementById('BatchNo')
                        // 
                        this.setState({ Selected_item: value },()=>{
                            document.getElementById('UOM').value = value.uom
                            
                            SelectBatchDetailsForSR(this.Comp_Id,this.ProjectID,value.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
                                
                                // this.BatchNO_Auto.props.options.splice(0, this.BatchNO_Auto.props.options.length)

                                // for (var i = 0; i < mItems.length; i++) {
                                // this.BatchNO_Auto.props.options.push(mItems[i])
                                // }
                                // this.setState({Batch_List:mItems})
                                var BatchNo_select = document.getElementById('BatchNo')

                                var length = BatchNo_select.options.length;
                                for (let i = length - 1; i >= 0; i--) {
                                    BatchNo_select.options[i] = null;
                                }
                                for (let i = 0; i < mItems.length; i++) {
                                    BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].BatchNo, mItems[i].BatchNo)
                                }
                                BatchNo_select.selectedIndex = 0
                                if(mItems.length > 0){
                                    document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
                                    document.getElementById('BQty').value = mItems[0].BQty
                                    document.getElementById('SaleRate').value = mItems[0].SaleRate
                                    document.getElementById('value').value = mItems[0].SaleRate
                                }
                                    this.setState({Batch_List:mItems,Selected_Batch:mItems[0]})
                            })
                        })
                       
                    }
                    else {
                        this.setState({ Selected_item: null,Batch_List:[],Selected_Batch:null })
                        document.getElementById('UOM').value = ''
                        document.getElementById('ExpairyDate').value = ''
                        document.getElementById('BQty').value = 0
                        document.getElementById('SaleRate').value = 0
                        document.getElementById('value').value = 0
                       
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
                this.Calculate_Total()
            }}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    BatchNo = (rowValue) => {
        return (<select id="BatchNo" name="BatchNo" 
            style={{ fontSize: 17, textAlign: "right", width: "100%" }}
            onChange={e => {
                var Qty = 0;
                this.state.Batch_List.find(value => {
                    if (e.target.value == value.BatchNo) {
                        this.setState({ Selected_Batch: value })
                            document.getElementById('ExpairyDate').value = value.ExpairyDate
                            document.getElementById('BQty').value = value.BQty
                            document.getElementById('SaleRate').value = value.SaleRate
                            document.getElementById('value').value = value.SaleRate
                    }
                    else {
                        this.setState({ Selected_Batch: null })
                        document.getElementById('ExpairyDate').value = ''
                        document.getElementById('BQty').value = ''
                        document.getElementById('SaleRate').value =  ''
                        document.getElementById('value').value = ''

                    }
                })
            }}
            className="textbox" ></select>)

    }
    // MBatchNo = (rowValue) => {
    //     return (<input
    //         id="MBatchNo"
    //         name="MBatchNo"
    //         style={{ textAlign: "right", width: "80%" }}
    //         defaultValue={rowValue.MBatchNo}
    //         className="textbox"
    //         onChange={e=> this.setState({MBatchNo : e.target.value})}
    //         onInput={this.toInputUppercase}
    //         disabled = {!this.props.isAddNew}
    //     />)
    // }
    // BatchNo = (rowValue) => {
    //     if(rowValue.index === undefined)
    //     {
    //         this.BatchNO_Auto = <Autocomplete
    //                 name="BatchNo"
    //                 id="BatchNo"
    //                 defaultValue={this.state.Batch_List.find(v => v.batchno === rowValue.BatchNo)}
    //                 options={this.state.Batch_List}
    //                 getOptionLabel={(option) => option.batchno ? option.batchno : ""}
    //                 ref={this.bg_Ref}
    //                 onChange={(e, value) => {
    //                     if (value) {
                            
    //                         this.setState({ Selected_Batch: value })
    //                         document.getElementById('ExpairyDate').value = value.ExpairyDate
    //                         document.getElementById('BQty').value = value.BQty
    //                         document.getElementById('SaleRate').value = value.SaleRate
    //                         document.getElementById('value').value = value.SaleRate
    //                     }
    //                     else {
    //                         this.setState({ Selected_Batch: null })
    //                         document.getElementById('ExpairyDate').value = ''
    //                         document.getElementById('BQty').value = ''
    //                         document.getElementById('SaleRate').value =  ''
    //                         document.getElementById('value').value = ''

    //                     }
    //                 }}
    //                 renderInput={(params) =>
    //                     <TextField {...params} name="BatchNo" />}
    //             />
    //     }
    //     else
    //     {
    //         this.BatchNO_Auto = <lable> { rowValue.BatchNo } </lable>
    //     }
        
    //     return (
    //         this.BatchNO_Auto
    //     )
    // }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            readOnly={true}

        />)
    }
    BQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BQty"
            defaultValue={rowValue.BQty}
            className="textbox"
            name="BQty"
            readOnly={true}
            
        />)
    }
    SaleRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="SaleRate"
            defaultValue={rowValue.SaleRate}
            className="textbox"
            name="SaleRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    SaveHandler = () => {
        if(this.state.SelectedCustomer)
        {
            this.setState({Supplier_Err:''})
            if(this.state.SI_Detail_Data.length > 0)
            {
                let _PurchaseValue = 0;
                var _TotalPaid = 0.00;
                var _GrandTotal = parseFloat(this.state.GrandTotal) > 0 ? -(parseFloat(this.state.GrandTotal)) : this.state.GrandTotal
                if(this.state.SelectedPaymentType.value === 'Account Sale'){
                    _TotalPaid = _GrandTotal
                }
                else if (this.state.SelectedPaymentType.value === 'Cash Sale'){
                    _TotalPaid = _GrandTotal
                }
                else
                {
                    _TotalPaid = this.state.TotalPaid
                }
               
                for(let i = 0; i< this.state.SI_Detail_Data.length; i++)
                {
                    if( typeof this.state.SI_Detail_Data[i].BatchNo === undefined || this.state.SI_Detail_Data[i].BatchNo === 'undefined' || this.state.SI_Detail_Data[i].BatchNo == '' || this.state.SI_Detail_Data[i].BatchNo == null )
                        {
                            Swal.fire({
                                icon: 'info',
                                text: `Batch No at Sr No ${this.state.SI_Detail_Data[i].SrNo} Must be Entered...`,
                            })
                            return
                        }
                    if(typeof this.state.SI_Detail_Data[i].Qty === 'undefined' || this.state.SI_Detail_Data[i].Qty == '' || this.state.SI_Detail_Data[i].Qty == null )
                    {
                        this.state.SI_Detail_Data[i].Qty = 0
                        if(typeof this.state.SI_Detail_Data[i].Qty === 'string')
                        {
                            if(parseFloat(this.state.SI_Detail_Data[i].Qty) >= 0)
                            {
                                this.state.SI_Detail_Data[i].Qty =  -(parseFloat(this.state.SI_Detail_Data[i].Qty))
                            }
                            else{
                                this.state.SI_Detail_Data[i].Qty =  (parseFloat(this.state.SI_Detail_Data[i].Qty))
                            }
                        }
                    }
                    else
                    {
                            if(parseFloat(this.state.SI_Detail_Data[i].Qty) >= 0)
                            {
                                this.state.SI_Detail_Data[i].Qty =  -(parseFloat(this.state.SI_Detail_Data[i].Qty))
                            }
                            else{
                                this.state.SI_Detail_Data[i].Qty =  (parseFloat(this.state.SI_Detail_Data[i].Qty))
                            }
                    }
                    // if(this.state.SI_Detail_Data[i].MBatchNo === '' || this.state.SI_Detail_Data[i].MBatchNo === undefined || this.state.SI_Detail_Data[i].MBatch === null){
                    //     if (typeof this.state.SI_Detail_Data[i].BatchNo === undefined || typeof this.state.SI_Detail_Data[i].BatchNo === 'undefined' || this.state.SI_Detail_Data[i].BatchNo == '' || this.state.SI_Detail_Data[i].BatchNo == null) {
                    //         Swal.fire({
                    //             icon: 'info',
                    //             text: `Add Batch NO at Sr No. ${this.state.SI_Detail_Data[i].SrNo}...`,
                    //         })
                    //         return
                    //     }
                    // }
                    

                    if(typeof this.state.SI_Detail_Data[i].TaxPer === 'undefined' || this.state.SI_Detail_Data[i].TaxPer == '' || this.state.SI_Detail_Data[i].TaxPer == null )
                    this.state.SI_Detail_Data[i].TaxPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].TaxPer === 'string')
                    this.state.SI_Detail_Data[i].TaxPer =  parseFloat(this.state.SI_Detail_Data[i].TaxPer)

                    if(typeof this.state.SI_Detail_Data[i].DisPer === 'undefined' || this.state.SI_Detail_Data[i].DisPer == '' || this.state.SI_Detail_Data[i].DisPer == null )
                    this.state.SI_Detail_Data[i].DisPer = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisPer === 'string')
                    this.state.SI_Detail_Data[i].DisPer =  parseFloat(this.state.SI_Detail_Data[i].DisPer)

                    if(typeof this.state.SI_Detail_Data[i].TaxAmt === 'undefined' || this.state.SI_Detail_Data[i].TaxAmt == '' || this.state.SI_Detail_Data[i].TaxAmt == null )
                    this.state.SI_Detail_Data[i].TaxAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].TaxAmt === 'string')
                    this.state.SI_Detail_Data[i].TaxAmt =  parseFloat(this.state.SI_Detail_Data[i].TaxAmt)

                    if(typeof this.state.SI_Detail_Data[i].DisAmt === 'undefined' || this.state.SI_Detail_Data[i].DisAmt == '' || this.state.SI_Detail_Data[i].DisAmt == null )
                    this.state.SI_Detail_Data[i].DisAmt = 0
                    else if (typeof this.state.SI_Detail_Data[i].DisAmt === 'string')
                    this.state.SI_Detail_Data[i].DisAmt =  parseFloat(this.state.SI_Detail_Data[i].DisAmt)

                    if(typeof this.state.SI_Detail_Data[i].SaleRate === 'undefined' || this.state.SI_Detail_Data[i].SaleRate == '' || this.state.SI_Detail_Data[i].SaleRate == null )
                    this.state.SI_Detail_Data[i].SaleRate = 0
                    else if (typeof this.state.SI_Detail_Data[i].SaleRate === 'string')
                    this.state.SI_Detail_Data[i].SaleRate =  parseFloat(this.state.SI_Detail_Data[i].SaleRate)
                    
                    if(typeof this.state.SI_Detail_Data[i].Value === 'undefined' || this.state.SI_Detail_Data[i].Value == '' || this.state.SI_Detail_Data[i].Value == null )
                    this.state.SI_Detail_Data[i].Value = 0
                    else if (typeof this.state.SI_Detail_Data[i].Value === 'string')
                    this.state.SI_Detail_Data[i].Value =  parseFloat(this.state.SI_Detail_Data[i].Value)
                    this.state.SI_Detail_Data[i].PurchaseValue=parseFloat((this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].PurchaseRate).toFixed(2)) 
                    _PurchaseValue += this.state.SI_Detail_Data[i].PurchaseValue
                }
                var OrderData = {
                    SaleInvoiceID:this.props.SaleInvoiceID,
                    SaleInvoiceNo:this.state.SaleInvoiceNo,
                    WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                    CustomerID:this.state.SelectedCustomer.CustomerID,
                    UserID:this.UserID,
                    CreatedDate: GetCurrentDate(),
                    InvoiceDate:this.state.InvoiceDate,
                    Discription:this.state.Discription,
                    TotalDiscount:this.state.TotalDiscount,
                    TotalQuantity:this.state.TotalQuantity,
                    InvoiceAmount:this.state.InvoiceAmount,
                    GrandTotal:_GrandTotal,
                    TransactionType:'SR',
                    BankID:this.state.SelectedBank?this.state.SelectedBank.BankID:0,
                    PaymentType:this.state.SelectedPaymentType.value,
                    TotalPaid:_TotalPaid,
                    Balance:this.state.Balance,
                    Comp_Id:this.Comp_Id,
                    ProjectID:this.ProjectID,
                    Cash: 0.00,
                    ChangeAmt: 0.00,
                    PurchaseAmount:_PurchaseValue,
                    ServiceCharges: 0
                }
                this.setState({btn_Disabled:true})
                SaveSaleInvoice(OrderData,this.state.SI_Detail_Data,this.props.isAddNew,()=>{
                    this.setState({btn_Disabled:false})
                    document.getElementById('btnCancel').click()
                })
            }
            else
            { 
                Swal.fire({
                    icon: 'info',
                    text: `Order Details Must be Entered...`,
                })
                return
            }
        }
        else
        {
            this.setState({Supplier_Err:'Supplier is required'})
        }
    }
    beforeRowInsert = (rowValue) => {


        if(rowValue.requestType === 'save')
        {
         
            rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
            rowValue.data.ItemID = this.state.Selected_item.ItemID
            rowValue.data.UOMID = this.state.UOMID
            // rowValue.data.MBatchNo = this.state.MBatchNo
            if(this.state.Selected_Batch){
           
                rowValue.data.BatchNo = this.state.Selected_Batch.BatchNo
            }
          
          else{
            rowValue.data.BatchNo = ''
          }
            // rowValue.data.BatchNo = this.state.Selected_Batch.batchno
            // if(parseFloat(rowValue.data.Qty) > parseFloat(rowValue.data.BQty))
            // {
            //     Swal.fire({
            //         icon: 'info',
            //         text: `Qty must be less than the Batch Qty : ${rowValue.data.BQty} Given...`,
            //     })
            //     this.grid.closeEdit()

            // }
            if (rowValue.data.SaleInvoiceDetailID === undefined)
                rowValue.data.action = 'add'
            else
                rowValue.data.action = rowValue.action
        }

        else if ( rowValue.requestType === 'add' )
        {
            rowValue.data.SrNo = this.state.SI_Detail_Data.length + 1
            rowValue.data.Qty = 1
            rowValue.data.DisPer = 0.00
            rowValue.data.DisAmt = 0.00
            rowValue.data.TaxPer = 0.00
            rowValue.data.TaxAmt = 0.00
            // rowValue.data.MBatchNo = ''

            this.setState({ Selected_item: null,UOMID:0 , Selected_Batch:null
                // ,MBatchNo :''
            })
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            // this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID },UOMID: rowValue.rowData.UOMID })
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID },UOMID: rowValue.rowData.UOMID,"PurchaseRate":rowValue.rowData.PurchaseRate
            // , MBatchNo: rowValue.rowData.MBatchNo
         },()=>{
                // this.Select_Batch_Detail(rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID)
                SelectBatchDetailsForSR(this.Comp_Id,this.ProjectID,rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
                    var BatchNo_select = document.getElementById('BatchNo')

                    var length = BatchNo_select.options.length;
                    for (let i = length - 1; i >= 0; i--) {
                        BatchNo_select.options[i] = null;
                    }

                    for (let i = 0; i < mItems.length; i++) {
                        if(mItems[i].BatchNo === rowValue.rowData.BatchNo){
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].BatchNo, mItems[i].BatchNo)
                            this.setState({Selected_Batch:mItems[i]})
                        }
                    }
                    BatchNo_select.selectedIndex = 0
                    this.setState({Batch_List:mItems})
                })
            })
            
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values();
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Values();
        }
    }

    Calculate_Total = () => {
        var Qty = 0, Rate = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

        Rate = document.getElementById('SaleRate').value == "" ? 0 : parseFloat(document.getElementById('SaleRate').value)
        DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)
        TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)

        DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        document.getElementById('DisAmt').value = DisAmt;
        document.getElementById('TaxAmt').value = TaxAmt;
        document.getElementById('value').value = Total_Value;

    }
    Calculate_Values = () =>{
        var _TotalQuantity = 0, _TotalDis = 0, _TotalTax = 0, _TotalPur = 0, _GrandTotal = 0, Rate=0, Qty=0
        for(let i = 0; i< this.state.SI_Detail_Data.length; i++)
        {
           if( typeof this.state.SI_Detail_Data[i].Qty === 'string' && typeof this.state.SI_Detail_Data[i].DisAmt === 'string' && typeof this.state.SI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.SI_Detail_Data[i].SaleRate === 'string' && typeof this.state.SI_Detail_Data[i].value === 'string'  )
           {
            this.state.SI_Detail_Data[i].Qty = parseFloat((this.state.SI_Detail_Data[i].Qty).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].DisAmt = parseFloat((this.state.SI_Detail_Data[i].DisAmt).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].TaxAmt = parseFloat((this.state.SI_Detail_Data[i].TaxAmt).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].SaleRate = parseFloat((this.state.SI_Detail_Data[i].SaleRate).replace(/,/g, ''))
            this.state.SI_Detail_Data[i].Value = parseFloat((this.state.SI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (this.state.SI_Detail_Data[i].Qty == '' && typeof this.state.SI_Detail_Data[i].DisAmt === '' && typeof this.state.SI_Detail_Data[i].TaxAmt === '' && typeof this.state.SI_Detail_Data[i].SaleRate === '' && typeof this.state.SI_Detail_Data[i].value === '' )
           {
            this.state.SI_Detail_Data[i].Qty = 0 
            this.state.SI_Detail_Data[i].DisAmt = 0
            this.state.SI_Detail_Data[i].TaxAmt = 0
            this.state.SI_Detail_Data[i].SaleRate = 0
           this.state.SI_Detail_Data[i].Value = 0
           }
           _TotalQuantity += parseFloat(this.state.SI_Detail_Data[i].Qty)
           _TotalDis += parseFloat(this.state.SI_Detail_Data[i].DisAmt)
           _TotalTax += parseFloat(this.state.SI_Detail_Data[i].TaxAmt)
           _TotalPur += parseFloat(this.state.SI_Detail_Data[i].Qty * this.state.SI_Detail_Data[i].SaleRate)
            _GrandTotal += parseFloat(this.state.SI_Detail_Data[i].Value)
        }
        this.setState({TotalQuantity:_TotalQuantity,TotalDiscount:_TotalDis,TotalTaxAmt:_TotalTax,InvoiceAmount:_TotalPur.toFixed(2),GrandTotal:_GrandTotal.toFixed(2)})
        
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        this.setState({ SI_Detail_Data: this.state.SI_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.SI_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray},()=>{
                this.Calculate_Values()
            })
        })
        this.Hide_Min_Stock_Modal()
    }
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
           <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title text-white" >
              <h4> Sale Return</h4>
            </Col>
          </Row>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="SaleInvoiceNo"
                            label="Sale Return No" 
                            value={this.state.SaleInvoiceNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="CustomerName"
                            id="CustomerName"
                            options={this.state.CustomerList}
                            getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                            value={this.state.SelectedCustomer}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedCustomer:value})
                                }
                                else
                                {
                                    this.setState({SelectedCustomer:null})
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="CustomerName" label="Customer"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                                    this.Select_Item_Details(value.WareHouseID)
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.InvoiceDate}
                            name="InvoiceDate"
                            label="InvoiceDate" 
                            type= 'date'
                            onChange= {e => this.setState({InvoiceDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PaymentType"
                            id="PaymentType"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedPaymentType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedPaymentType:value},()=>{
                                        if(value.value === 'Account Sale'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                        else if (value.value === 'Account Credit Sale'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Credit Sale'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Sale'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                    })
                              
                                }
                                else
                                {
                                    this.setState({SelectedPaymentType:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PaymentType" label="Payment Type"
                                />}
                        />
                        {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                
                    
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="BankName"
                            id="BankName"
                            options={this.state.BankList}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            value={this.state.SelectedBank}
                            disabled={this.state.Bank_Disable_Prop}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedBank:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedBank:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="BankName" label="BankName"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalPaid"
                            label="TotalPaid" 
                            value={this.state.TotalPaid}
                            disabled={this.state.Total_Disable_Prop}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalPaid:e.target.value},()=>{
                                let bal =   this.state.TotalPaid - this.state.GrandTotal
                                  this.setState({Balance:bal})
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Balance"
                            label="Balance" 
                            value={this.state.Balance}
                            disabled={this.state.Balance_Disable_Prop}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Balance:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField
                        name="RefInvoiceNo"
                        label="Reference Invoice No" 
                        value={this.state.RefInvoiceNo}
                        onInput={this.toInputUppercase}
                        onChange={e=>this.setState({RefInvoiceNo:e.target.value},()=>{
                            if(e.target.value.length != 6 ){
                                this.setState({SI_Detail_Data:null,TotalDiscount:0,TotalQuantity:0,InvoiceAmount:0,GrandTotal:0,Cash:0,ChangeAmt:0})
                        
                            }
                            if(e.target.value.length == 6){
                                SelectSaleInvoiceMasterWRTCode(this.Comp_Id,this.ProjectID,this.state.RefInvoiceNo,(mData)=>{
                                  if(mData.length > 0) {
                                    this.setState({  
                                        TotalDiscount:mData[0].TotalDiscount,
                                        TotalQuantity:mData[0].TotalQuantity,
                                        InvoiceAmount:mData[0].InvoiceAmount,
                                        GrandTotal:mData[0].GrandTotal,
                                        Cash:mData[0].Cash,
                                        ChangeAmt:mData[0].ChangeAmt,    
                                        PurchaseAmount: mData[0].PurchaseAmount                                
                                    })
                    
                                    SelectSaleInvoiceDetailWRTCode(this.Comp_Id,this.ProjectID,this.state.RefInvoiceNo,(mDetailData)=>{ 
                                        mDetailData.map((value,index)=>{
                                            value.SrNo = index + 1 
                                            value.action = 'add'
                                            value.SaleInvoiceDetailID=undefined
                                            // value.MBatchNo = ''
                                            value.Qty = value.Qty
                                        })
                                        this.setState({SI_Detail_Data:mDetailData})
                                    })
                                  }
                                  else{
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Oops...',
                                        text: `No Data Found on this Invoice No`,
                                      })
                                  }
                                  
                                })
                            }
                            
                        })}
                        fullWidth
                    />
                </Col> 
                </Row>
             
                    
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.SI_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition:'Top'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'dropdownedit'  editTemplate={this.ItemDiscription}   edit={this.cbParams}  validationRules={this.NAmeValidation} textAlign='Left'/> 
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType= 'dropdownedit'  editTemplate={this.BatchNo}   edit={this.cbParams}   textAlign='Left'/> 
                     {/* <ColumnDirective width='200' field='MBatchNo'  headerText='Market BatchNo' editTemplate={this.MBatchNo} ></ColumnDirective>*/}
                                    <ColumnDirective width='170' field='ExpairyDate' headerText='Expiry Date'  editTemplate={this.ExpairyDate} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='BQty' headerText='Batch Balance'   editTemplate={this.BQty} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right'/>
                                    <ColumnDirective width='150' field='SaleRate' headerText='SaleRate'   editTemplate={this.SaleRate} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis%'   editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt'   editTemplate={this.DisAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText='Tax%'   editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' headerText='TaxAmt'   editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='right'></ColumnDirective>

                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='SaleInvoiceDetailID' headerText='SaleInvoiceDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='SaleInvoiceID' headerText='SaleInvoiceID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalQuantity"
                            label="TotalQuantity" 
                            value={this.state.TotalQuantity}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalQuantity:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDiscount"
                            label="TotalDiscount" 
                            value={this.state.TotalDiscount}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalDiscount:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt"
                            label="TotalTaxAmt" 
                            value={this.state.TotalTaxAmt}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalTaxAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={2} >
                        <TextField
                            name="InvoiceAmount"
                            label="InvoiceAmount" 
                            value={this.state.InvoiceAmount}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({InvoiceAmount:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={2} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            value={this.state.GrandTotal}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    </Row>
                  
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                    <Link id="btnGetItems" className="btn btn-primary" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Items from Sale Invoice</u></Link>
                    {this.state.btn_edit &&(
                        <button type="button"
                         className="btn btn-primary"
                           style={{border: "none"}}
                            onClick={this.SaveHandler}
                            disabled={this.state.btn_Disabled}
                            > 
                            {this.state.btn_Disabled?(
                                <><Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> Saving... </> ):<><Save /> <u>Save</u></>}
                            </button>)}
                            <Link id="btnCancel" className="btn btn-primary" style={{border: "none"}} to={'/SaleReturnList'} > <Cancel /> <u>Close</u></Link>
                            <Link target={"_blank"}
                             className="btn btn-primary" 
                             style={{border: "none"}} 
                             to={'/SaleReturnReport'} 
                             onClick={e =>{

                                var obj = {
                                    PurchaseInvoiceID:this.state.PurchaseInvoiceID,
                                    PurchaseDate:this.state.InvoiceDate,
                                    Discription:this.state.Discription,
                                    TotalDisAmt:this.state.TotalDisAmt,
                                    TotalTaxAmt:this.state.TotalTaxAmt,
                                    TotalQty:this.state.TotalQty,
                                    GrandTotal:this.state.GrandTotal,
                                    PurchaseValue:this.state.PurchaseValue,
                                    PurchaseInvoiceNo: this.state.SaleInvoiceNo,
                                    Supplier: this.state.SelectedCustomer,
                                    Warehouse: this.state.SelectedWareHouse,
                                    PaymentType: this.state.SelectedPaymentType,
                                    Bank: this.state.SelectedBank,
                                    Detail: this.state.SI_Detail_Data
                                }

                                localStorage.setItem("SaleReturnReport", JSON.stringify(obj))
                             }}
                             > <Print /> <u>Print</u></Link>
                        </Col>
                    </Row>
                    <MinStockModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        project_Id= { this.ProjectID }
                        invoiceDate = { this.state.InvoiceDate }
                    />
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                  />
                </Card>
            
        </>, document.getElementById('mainContent')
    )
}

}  

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.SI && state.SI.isAddNew,
    SaleInvoiceID:state.SI && state.SI.SaleInvoiceID,
    ProjectID: state.SI && state.SI.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(SaleInvoiceEntry)